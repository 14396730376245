/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://5wkgkjo465d43f5cp2pjhuckwe.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-iedvda5bdbhbbmelvjt7oxir7m",
    "aws_cloud_logic_custom": [
        {
            "name": "oneschool",
            "endpoint": "https://ku9k1y9zca.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:e77021ee-c569-452b-bdc4-f83fc7b98ac8",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_oCQiU2fVe",
    "aws_user_pools_web_client_id": "752omrc1bft43kj0bdejomsesh",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "oneschoolviewc85329deadba43ee8fe9cce8de8d11e7134107-production",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
