import React from "react";
import logo from "../../logo.svg"

const Userinfo = ({ currentStudent }) => {
  return (
    <div className="card">
      <img src={currentStudent.Image} alt="avatar"  onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src=logo;
      }} />
      <h3>{currentStudent.firstName + " "+ currentStudent.lastName}</h3>
      <span className="card-info">
        <span>Building Entry</span>
        <p>{currentStudent.allowEntry ? "Yes" : "No"}</p>
      </span>
      <span className="card-info">
        <span>Student Id Number</span>
        <p>{currentStudent.id}</p>
      </span>
      <span className="card-info">
        <span>Student Access Card Id</span>
        <p>{currentStudent.cardId}</p>
      </span>
      <span className="card-info">
        <span>Current Subject</span>
        <p>{currentStudent.STATUSES.items.length > 0 ? currentStudent.STATUSES.items[0].subject : "" }</p>
      </span>
    </div>
  );
};

export default Userinfo;
