import React, { useState, useEffect, useRef } from "react";
import {colorToRGBA} from "../../utils/Utils";
function UpdateSubject({ updateSubject,currentSubject, setCurrentSubject, }) {
  //hold user data
  const formInit = {
      name: "",
      classId: "",
      sectionId: "",
      color: "",
      rgbActive: "",
      rgbNotActive: "",
      hidden: false,
      building: "",
      maxTime: 42
  };
  const [formData, setFormData] = useState(formInit);
    useEffect(() =>{
        setFormData({...currentSubject});

    },[currentSubject]);
  //hangle change event
  const handleChange = (event) => {
    const key = event.target.id;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setFormData({ ...formData, [key]: value });
  };
  //handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    let bg = colorToRGBA(formData["color"])
    formData["rgbActive"] =`rgba(${bg[0]}, ${bg[1]}, ${bg[2]}, .50)`
    formData["rgbNotActive"] =`rgba(${bg[0]}, ${bg[1]}, ${bg[2]}, .25)`
      updateSubject(currentSubject.name,formData);
  };

  return (
    <form className="addnew" onSubmit={handleSubmit}>
      <label className="required">Unique Class Id</label>
      <input
        id="sectionId"
        type="text"
        className="inputs"
        value={formData.sectionId}
        onChange={handleChange}
        placeholder="Doe"
      />
      <label className="required">Display Color</label>
      <input
        id="color"
        type="text"
        className="inputs"
        value={formData.color}
        onChange={handleChange}
        placeholder="green"
      />
      <label className="required">Building</label>
      <input
        id="building"
        type="text"
        className="inputs"
        value={formData.building}
        onChange={handleChange}
        placeholder="synergy"
      />
        <label className="required">Max Class Time</label>
        <input
            id="maxTime"
            type="number"
            className="inputs"
            value={formData.maxTime}
            onChange={handleChange}
        />

      <span className="checkbox">
        Hide Subject From Options
        <input
          id="hidden"
          type="checkbox"
          checked={formData.hidden}
          onChange={handleChange}
        />
      </span>
      <button className="btn-submit">Update</button>
    </form>
  );
}

export default UpdateSubject;
