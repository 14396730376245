import React from 'react'

const Footer=()=> {
  return (
    <footer>
        <h4>Copyright 2022-2023 <a>Ark TCI</a>. All rights reserved.</h4>
        <h4>Version 1.0.0</h4>
    </footer>
  )
}

export default Footer
