import React from "react";
import { Authenticator  } from '@aws-amplify/ui-react';


function handleAuthStateChange(state) {
  if (state === 'signedIn') {
    /* Do something when the user has signed-in */
  }
}

function Login() {


  return (
    <section id="login">
      <div className="container__login">
        <h2>
          Clock-Me<span>System</span>
        </h2>
        <Authenticator hideSignUp={true} onStateChange={handleAuthStateChange} >
        </Authenticator>
      </div>
    </section>
  );
}

export default Login;
