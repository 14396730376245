/*eslint-disable*/
import React, {useEffect} from "react";
import { useTable, useGroupBy, useExpanded,   useSortBy, usePagination,   useResizeColumns,
    useFlexLayout,
    useRowSelect, useMountedLayoutEffect } from 'react-table'
import classnames from "classnames";
import BTable from 'react-bootstrap/Table';
// A great library for fuzzy filtering/sorting items
import { matchSorter } from "match-sorter";
// react plugin used to create DropdownMenu for selecting items


// reactstrap components
import { Container, Row, Col, FormGroup, Input } from "reactstrap";

// Define a default UI for filtering
const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        )
    }
)


// Define a default UI for filtering
function DefaultColumnFilter({
                                 column: { filterValue, preFilteredRows, setFilter },
                             }) {
    const count = preFilteredRows.length;

    return (
        <FormGroup>
            <Input
                placeholder={`Search ${count} records...`}
                type="text"
                onChange={(e) => {
                    setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
                }}
            />
        </FormGroup>
    );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;


const defaultPropGetter = () => ({})

function Table({ columns, data,setSelectedRows,initialState, getRowProps = defaultPropGetter, }) {
    const defaultColumn = React.useMemo(
        () => ({
            // When using the useFlexLayout:
            minWidth: 30, // minWidth is only used as a limit for resizing
            width: 150, // width is used for both the flex-basis and flex-grow
            maxWidth: 200, // maxWidth is only used as a limit for resizing
            Filter: DefaultColumnFilter,
        }),
        []
    )
    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true;
                });
            },
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
        state: { selectedRowIds },

    } = useTable(
        {
            columns,
            data,
            initialState
        },
        useGroupBy,
        useSortBy,
        useExpanded,
        usePagination,
        useResizeColumns,
        useFlexLayout,
        useRowSelect,
        hooks => {
            hooks.allColumns.push(columns => [
                // Let's make a column for selection
                {
                    id: 'selection',
                    disableResizing: false,
                    minWidth: 35,
                    width: 50,
                    maxWidth: 100,
                    // The header can use the table's getToggleAllRowsSelectedProps method
                    // to render a checkbox
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <div>
                            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                        </div>
                    ),
                    // The cell can use the individual row's getToggleRowSelectedProps method
                    // to the render a checkbox
                    Cell: ({ row }) => (
                        <div>
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                        </div>
                    ),
                },
                ...columns,
            ])
            hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
                // fix the parent group of the selection button to not be resizable
                const selectionGroupHeader = headerGroups[0].headers[0]
                selectionGroupHeader.canResize = false
            })
        }

    )

    // We don't want to render all of the rows for this example, so cap
    // it at 100 for this use case
    const firstPageRows = rows.slice(0, 100)
    useMountedLayoutEffect(() => {
        const selectedIds = Object.keys(selectedRowIds);
        const selectedRowsData = selectedIds
            .map(x => data[x])
            .filter(function(x) {
                return x != null;
            });
        setSelectedRows(selectedRowsData);
    }, [setSelectedRows, selectedRowIds]);

    return (
        <>
            <div className="results">
                <BTable striped bordered hover size="sm" {...getTableProps()}>
                <thead className="rt-thead -header">
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()} className={"rt-tr text-center"}>
                        {headerGroup.headers.map((column,key) => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                className={classnames("rt-th text-center text-wrap", {
                                    "-cursor-pointer": headerGroup.headers.length - 1 !== key,
                                    "-sort-asc": column.isSorted && !column.isSortedDesc,
                                    "-sort-desc": column.isSorted && column.isSortedDesc,
                                })}
                            >
                                {column.canGroupBy ? (
                                    // If the column can be grouped, let's add a toggle
                                    <span {...column.getGroupByToggleProps()} ></span>
                                ) : null}
                                {column.render('Header')}
                                {/* Use column.getResizerProps to hook up the events correctly */}
                                {/* && column.canResize && (
                                    <div
                                        {...column.getResizerProps()}
                                        className={`resizer ${
                                            column.isResizing ? 'isResizing' : ''
                                        }`}
                                    />

                                )*/}
                                {column.isSorted}

                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()} className="rt-tbody text-center">
                {firstPageRows.map((row, i) => {
                    prepareRow(row)
                    return (

                        <tr {...row.getRowProps(getRowProps(row))}>

                            {row.cells.map(cell => {
                                return (
                                    <td   className="rt-td"
                                        // For educational purposes, let's color the
                                        // cell depending on what type it is given
                                        // from the useGroupBy hook
                                        {...cell.getCellProps()}
                                    >
                                        {cell.render('Cell')}
                                    </td>
                                )
                            })}
                        </tr>
                    )
                })}
                </tbody>
                </BTable>
            <br />

            </div>
        </>
    )
}

function Legend() {
    return (
        <div
            style={{
                padding: '0.5rem 0',
            }}
        >
      <span
          style={{
              display: 'inline-block',
              background: '#0aff0082',
              padding: '0.5rem',
          }}
      >
        Grouped
      </span>{' '}
            <span
                style={{
                    display: 'inline-block',
                    background: '#ffa50078',
                    padding: '0.5rem',
                }}
            >
        Aggregated
      </span>{' '}
            <span
                style={{
                    display: 'inline-block',
                    background: '#ff000042',
                    padding: '0.5rem',
                }}
            >
        Placeholder
      </span>
        </div>
    )
}
// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
    return rows.filter((row) => {
        const rowValue = row.values[id];
        return rowValue >= filterValue;
    });
}

export default Table;