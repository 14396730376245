import React, {useEffect, useRef, useState} from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import Userinfo from "../components/cards/Userinfo";
import ReactTable from "../components/React-Table/ReactTable";
import Welcomeinfo from "../components/cards/Welcomeinfo";
import Starts from "../components/cards/Starts";
import {Button} from "@aws-amplify/ui-react";
import Select from "react-select";
import SubjectAlert from "../components/Alert/SubjectAlert"
import {addTrack, buildingCheckOut,buildingCheckIn} from "../Services/idcardActions";
import {DashboardUseSubscription} from "../Services/subscriptions";
const studentInit = {
  id: "",
  firstName: "",
  lastName: "",
  cardId: "",
  familyId: "",
  adUsername: "",
  imageName: "",
  allowLunch: "",
  accessGroup: "",
  allowEntry: "",
  district: "",
  inBuilding: "",
  Image: "",
  currentStatus: "",
  classId:"",
  STATUSES: {items:[]},
  SUBJECTS: {items:[]}
};
const columns = [

  {
    Header: 'First Name',
    accessor: 'firstName',
  },
  {
    Header: 'Last Name',
    accessor: 'lastName',
  },
  {
    Header: 'Status',
    accessor: 'STATUSES.items[0].subject',
  }

]
const options = [
  { value: 'Art', label: 'Art' },
  { value: 'Check-In', label: 'Check-In' },
  { value: 'Check-Out', label: 'Check-Out' },
  { value: 'ELA', label: 'ELA' },
  { value: 'Family Living', label: 'Family Living' },
  { value: 'Foreign Language', label: 'Foreign Language' },
  { value: 'Health', label: 'Health' },
  { value: 'Lunch', label: 'Lunch' },
  { value: 'Math', label: 'Math' },
  { value: 'Physical Education', label: 'Physical Education' },
  { value: 'Science', label: 'Science' },
  { value: 'Social Studies', label: 'Social Studies' }
]


const Admin = ({
  fetchUsers,
    building,
  currentStudent,
  setCurrentStudent,
  searchTerm,
  handleSearch,
  accName
}) => {
  const [alert, setAlert] = React.useState({
    type: 'error',
    text: 'This is a alert message',
    show: false
  })
  const [inBuildingCount, setInBuildingCount] = useState(0);
  const inputEl = useRef("");
  const navigate = useNavigate()
  const [message, setMessage]= useState();
  const [selectedRows, setSelectedRows] = React.useState({});
  const [students, setStudents] = useState([]);
  const handleSelection = React.useCallback((value) => {
    setSelectedRows(value);
  }, []);
;
  DashboardUseSubscription({students, setStudents, inBuildingCount, setInBuildingCount, building,sortDirection:"DESC"});

    const handleBulkUpdate = (selection) => {
      setAlert({
            text: "",
            show: false,
            type: "warning",
          }
      )
      selectedRows.map((row) => {
        if(selection === "Check-In"){
          buildingCheckIn(row, "attendanceCheckIn", building)
        } else if (selection === "Check-Out"){
          buildingCheckOut(row, "attendanceCheckOut", building)
        } else {
          const selected_item = row.SUBJECTS.items.filter((subject) => {
            return subject.name.toLowerCase() === selection.toLowerCase();
          });
          addTrack(row, selected_item[0], building);
        }

      })

    }
    const handleSelectChange = (selection) => {
    const names = selectedRows.map((row) => {
        return row.firstName + " " + row.lastName;
    })
        const text = (
            <>
              <h4>Are you sure you want to bulk update {names.length} students to {selection.label}?</h4>
              <small>{names.join(', ') }</small>
              <Button className="btn-subject btn" style={{color: "blue"}} onClick={() => handleBulkUpdate(selection.value)}>Confirm</Button>
            </>
        )
    setAlert({
          text: text,
          show: true,
          type: "warning",
        }
    )
    console.log("Bulk Update", selection);

  }
  const handleFilterFunction = () => {
    handleSearch(inputEl.current.value);
    
  };
  if (students.length === 0) {
    fetchUsers();
  }
  const handleAddSubject = async (newvalue) =>{

    addTrack(currentStudent, newvalue,building);
    onCloseAlert()

  }
  const renderUser = (id) => {
    const user = students.filter((element) => {
      return element.id === id;
    });

    setCurrentStudent(JSON.parse(JSON.stringify(user[0])));
    navigate('update')
  };
  function onCloseAlert() {
    setAlert({
      type: '',
      text: '',
      show: false
    })
  }
  useEffect(() =>{
    function onShowAlert(type) {
      setAlert({
        type: type,
        text: <>
          {message}
        </>,
        show: true
      })
    }

    if((currentStudent.id !== "") && (message !== undefined)){
      if(currentStudent.allowEntry){
        if((currentStudent.STATUSES.items.length === 0) || (!currentStudent.inBuilding)){
          buildingCheckIn(currentStudent, "attendanceCheckIn", building)

        }

        onShowAlert('success')
      } else {
        onShowAlert('error')
      }
    }

  }, [message])
  function handleAddEvent() {
    let subj = currentStudent.SUBJECTS.items;
    let filteredResults = [];
    if (subj.length > 0){
      filteredResults = subj && subj.filter(subj=>subj.hidden === false)
    }
    if (!currentStudent.inBuilding) {
      setMessage(<div><h4>{currentStudent.firstName + " " + currentStudent.lastName} has been check in</h4></div>);
    } else {
      setMessage(
          <div>
            <h4>Choose subject for {currentStudent.firstName + " " + currentStudent.lastName}</h4>
            <div/>
            <div className="btn-grid">
              {filteredResults.map((activity) => (
                  <div className={"btn-subject-cont"} key={activity.name + "-" + activity.classId}>
                    <Button className="btn-subject btn" style={{color: activity.rgbActive}}
                            onClick={() => handleAddSubject(activity)}>{activity.name}</Button>
                  </div>
              ))}
              <div className={"btn-subject-cont"} key="clockOut">
                <button className="btn-in btn" onClick={clockOutTime}>
                  Clock-Out
                </button>
              </div>

            </div>
          </div>);
    }
  }
  const clockOutTime = async () => {
    //chech if user acccount is selected
    buildingCheckOut(currentStudent, "attendanceCheckOut", building);

  };


  function getBackgroundColor(student) {
    if (student.STATUSES.items.length > 0) {
      if ((student.inBuilding) && (student.STATUSES.items[0].active) && (student.STATUSES.items[0].subject != "attendanceCheckIn")) {
        console.log(student)
        return student.STATUSES.items[0].SUBJECT.rgbActive;
      } else if ((student.inBuilding) && (!student.STATUSES.items[0].active) & (student.STATUSES.items[0].subject != "attendanceCheckIn")) {
        return  student.STATUSES.items[0].SUBJECT.rgbNotActive;
      } else if (student.inBuilding && (student.STATUSES.items[0].active) && (student.STATUSES.items[0].subject === "attendanceCheckIn")) {
        return "rgba(0, 255, 0, .25)"
      } else {
        return "rgba(255, 255, 0, .25)"
      }
    } else if (student.inBuilding) {
      return "rgba(0, 255, 0, .25)"
    } else {
      return "rgba(255, 255, 0, .25)"
    }
  }

  return (
    <section className="admin__view">
      <article className="left">
        <Welcomeinfo accName={accName}/>
        <Starts students={students} inBuildingCount={inBuildingCount}/>

        <div className="users-list">
          <div className="user-title">
            <h3>Manage Students</h3>
            <Select
              options={options}
              onChange={handleSelectChange}
            />
          </div>
          {students.length == 0 ? null : (<ReactTable
            data={students}
            columns={columns}
            setSelectedRows={handleSelection}
            initialState={{ selectedRowIds: selectedRows }}
            getRowProps={row => ({
              style: {
                background: getBackgroundColor(row.original),
              },
              onClick: () => renderUser(row.original.id),

            })}

          />)}
        </div>
      </article>
      <article className="right">
        <Userinfo currentStudent={currentStudent} />
        <div className="right-manage">
          <SubjectAlert
              header={`Welcome to ${building}`}
              btnText={'Close'}
              text={alert.text}
              type={alert.type}
              show={alert.show}
              onClosePress={onCloseAlert}
              pressCloseOnOutsideClick={true}
              showBorderBottom={true}
              alertStyles={{}}
              headerStyles={{}}
              textStyles={{}}
              buttonStyles={{}}
          />
          <NavLink className="btn-new btn" activeclassname="active" to="update">
            Update Info
          </NavLink>
          <NavLink className="btn-new btn" activeclassname="active" to="addnew">
            Add New?
          </NavLink>
          <button className="btn-new btn" onClick={handleAddEvent}>Enter Time</button>
        </div>
        <div className="forms-container">
          <Outlet />
        </div>
      </article>
    </section>
  );
};

export default Admin;
