import React from 'react'

const SubjectList=({subjects,renderSubject})=> {
  return (
    <div className="results" >
    {subjects.map((subject) => (
      <span style={{background:subject.rgbNotActive}}
        key={subject.name+"-"+subject.classId}
        onClick={() => {
          renderSubject(subject.name, subject.classId);
        }}
      >
        <h4 style={{color:"black"}}>{subject.name }</h4>
        <small style={{color:"black"}}>{subject.classId}</small>
      </span>
    ))}
  </div>
  )
}

export default SubjectList
