import React, {useEffect, useState} from "react";
import { NavLink } from "react-router-dom";
import {
  MdSpaceDashboard,
  MdAdminPanelSettings,
  MdAnalytics,
  MdOutlineAccessTimeFilled,
  MdOutlineClose,
  MdWbSunny,
} from "react-icons/md";
import { IoTimer } from "react-icons/io5";
import { BsMoonFill } from "react-icons/bs";
import logo from "../assets/images/Mineola-Logo.png"
import Synergy from "../assets/images/Synergy.png";
const Aside = ({groups }) => {
  const [adminFunctions, setAdminFunctions] = useState();


  useEffect(() => {
    if (groups.includes("Admin")){
      setAdminFunctions(
          <div>


          <NavLink
              className="nav__link"
              activeclassname="active"
              to="/home/admin">
            <MdAdminPanelSettings/>
            <h3>Admin</h3>
        </NavLink>
        <NavLink
          className="nav__link"
          activeclassname="active"
          to="/home/subjectadmin">
        <MdAdminPanelSettings/>
        <h3>Subject Admin</h3>
      </NavLink>
          </div>
    )
    }
  }, [groups])

  return (
    <aside>
      <div className="top">
        <div className="logo">
          <img src={logo} alt="Mineola Logo" className="logo__icon" />
          {"\n"}
          <h2>{"\n"}Time Tracker</h2>
        </div>
        <div className="close">
          <MdOutlineClose />
        </div>
      </div>

      <fieldset className="side-bar" title="manage">
        <legend>MANAGE</legend>

        <NavLink
          className="nav__link"
          activeclassname="active"
          to="/home/dashboard"
        >
          <MdSpaceDashboard />
          <h3>Dashboard</h3>
        </NavLink>
        <NavLink
          className="nav__link"
          activeclassname="active"
          to="/home/analytics"
        >
          <MdAnalytics />
          <h3>Analytics</h3>
        </NavLink>

        <NavLink
          className="nav__link"
          activeclassname="active"
          to="/home/timecard"
        >
          <MdOutlineAccessTimeFilled />
          <h3>Timecard</h3>
        </NavLink>
        {adminFunctions}
      </fieldset>
      <fieldset className="preference" title="preference">
        <legend>PREFRENCES</legend>
        <div className="content-wrapper">
          <h3>Theme</h3>
          <div className="theme-toggler">
            <span className="active">
              <MdWbSunny />
            </span>
            <span>
              <BsMoonFill />
            </span>
          </div>
        </div>
      </fieldset>

      <div className="aside__footer">
      </div>
    </aside>
  );
};

export default Aside;
