import React, {useState, useEffect} from "react";
import ReactDOM from 'react-dom';
import { useNavigate } from "react-router-dom";
import Main from "./features/Main";
import Login from "./features/Login";
import './charts/ChartjsConfig';
import { useAuthenticator } from '@aws-amplify/ui-react';
import awsExports from './aws-exports';
import {Amplify} from "aws-amplify";
import Aside from "./components/nav/Aside";
Amplify.configure(awsExports);
const App = () => {

    const { user, signOut } = useAuthenticator((context) => [context.user]);
    const { authStatus } = useAuthenticator(context => [context.authStatus]);
    const [groups, setGroups] =useState([]);
    const navigate = useNavigate();
    useEffect(() =>{
        if(authStatus === 'authenticated' ){
            setGroups(user.signInUserSession.accessToken.payload["cognito:groups"])
        }

    },[authStatus])


    return (

                <>

                    {authStatus !== 'authenticated' ? <Login/> :

                        <main className="App">
                            <Aside groups={groups}/>
                            <Main user={user} signOut={signOut}/>
                        </main>

                    }


                </>


            );
};

export default App;