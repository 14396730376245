import React, { useState } from "react";
import { RiLogoutCircleLine } from "react-icons/ri";
import Clock from "./cards/CurrentClock"
import { MdMenu } from "react-icons/md";
import Aside from "./nav/nav";
const Header = ({user, signOut}) => {
  const [toggleNavBar, setToggle] = useState(false);

  return (

    <header>
      <span className={"clock"}><Clock/></span>
      <button onClick={signOut} />
      <span>
        <RiLogoutCircleLine></RiLogoutCircleLine>
        <h3 onClick={signOut}> Logout </h3>
      </span>
    </header>
  );
};

export default Header;
