import React from "react";
import {
  Text,
  Button,
  CheckboxField,
  TextField
} from "@aws-amplify/ui-react";

const Subjectinfo = ({ currentSubject }) => {
  return (
    <div className="card">
      <h3>{currentSubject.name} </h3>
      <span className="card-info">
        <span>Class Id</span>
        <p>{currentSubject.classId}</p>
      </span>
      <span className="card-info">
        <span>Building</span>
        <p>{currentSubject.building}</p>
      </span>
      <span className="card-info">
        <span>Max Time</span>
        <p>{currentSubject.maxTime}</p>
      </span>
    </div>
  );
};

export default Subjectinfo;
