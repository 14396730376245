/* eslint-disable */
// this is an auto generated file. This will be overwritten
export const startQuery = /* GraphQL */ `
  query StartQuery($input: QueryInput!) {
    startQuery(input: $input) {
      QueryExecutionId
      file {
        bucket
        region
        key
      }
    }
  }
`;
export const getSTATUS = /* GraphQL */ `
  query GetSTATUS($id: ID!) {
    getSTATUS(id: $id) {
      id
      subject
      studentId
      active
      time
      building
      checkInTime
      checkOutTime
      color
      classId
      SUBJECT {
            name
            sectionId
            hidden
            color
            classId
            maxTime
          }
      createdAt
      updatedAt
    }
  }
`;
export const listSTATUSES = /* GraphQL */ `
  query ListSTATUSES(
    $filter: ModelSTATUSFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSTATUSES(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        subject
        studentId
        active
        time
        building
        checkInTime
        checkOutTime
        color
        classId
        SUBJECT {
           name
            sectionId
            hidden
            color
            classId
            maxTime
            rgbActive
            rgbNotActive
          }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const syncSTATUSES = /* GraphQL */ `
  query SyncSTATUSES(
    $filter: ModelSTATUSFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSTATUSES(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        subject
        studentId
        active
        time
        building
        checkInTime
        checkOutTime
        color
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const statusByStudentSubject = /* GraphQL */ `
  query StatusByStudentSubject(
    $studentId: String!
    $buildingSubjectCheckInTime: ModelSTATUSStatusByStudentSubjectCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSTATUSFilterInput
    $limit: Int
    $nextToken: String
  ) {
    statusByStudentSubject(
      studentId: $studentId
      buildingSubjectCheckInTime: $buildingSubjectCheckInTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        subject
        studentId
        active
        time
        building
        checkInTime
        checkOutTime
        color
        classId
        SUBJECT {
           name
            sectionId
            hidden
            color
            classId
            maxTime
          }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const statusByStudent = /* GraphQL */ `
  query StatusByStudent(
    $studentId: String!
    $buildingCheckInTime: ModelSTATUSStatusByStudentCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSTATUSFilterInput
    $limit: Int
    $nextToken: String
  ) {
    statusByStudent(
      studentId: $studentId
      buildingCheckInTime: $buildingCheckInTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        subject
        studentId
        active
        time
        building
        color
        classId
        SUBJECT {
           name
            sectionId
            hidden
            color
            classId
            maxTime
            rgbActive
            rgbNotActive
          }
        checkInTime
        checkOutTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchSTATUSES = /* GraphQL */ `
  query SearchSTATUSES(
    $filter: SearchableSTATUSFilterInput
    $sort: [SearchableSTATUSSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableSTATUSAggregationInput]
  ) {
    searchSTATUSES(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        subject
        studentId
        active
        time
        building
        color
        classId
        checkInTime
        checkOutTime
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getSUBJECTS = /* GraphQL */ `
  query GetSUBJECTS($name: String!, $classId: String!) {
    getSUBJECTS(name: $name, classId: $classId) {
      name
      color
      rgbActive
      rgbNotActive
      sectionId
      hidden
      maxTime
      classId
      createdAt
      updatedAt
    }
  }
`;
export const listSUBJECTS = /* GraphQL */ `
  query ListSUBJECTS(
    $name: String
    $classId: ModelStringKeyConditionInput
    $filter: ModelSUBJECTSFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSUBJECTS(
      name: $name
      classId: $classId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        name
        color
        rgbActive
        rgbNotActive
        sectionId
        hidden
        building
        maxTime
        classId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const syncSUBJECTS = /* GraphQL */ `
  query SyncSUBJECTS(
    $filter: ModelSUBJECTSFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSUBJECTS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
      name
      color
      rgbActive
      rgbNotActive
      sectionId
      hidden
      building
      maxTime
      classId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDISTRICT = /* GraphQL */ `
  query GetDISTRICT($name: String!, $building: String!) {
    getDISTRICT(name: $name, building: $building) {
      name
      building
      timeZone
      scheduleCheck
      notifications
      createdAt
      updatedAt
    }
  }
`;
export const listDISTRICTS = /* GraphQL */ `
  query ListDISTRICTS(
    $name: String
    $building: ModelStringKeyConditionInput
    $filter: ModelDISTRICTFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDISTRICTS(
      name: $name
      building: $building
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        name
        building
        timeZone
        scheduleCheck
        notifications
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const syncDISTRICTS = /* GraphQL */ `
  query SyncDISTRICTS(
    $filter: ModelDISTRICTFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDISTRICTS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        name
        building
        timeZone
        scheduleCheck
        notifications
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSTUDENTS = /* GraphQL */ `
  query GetSTUDENTS(
                    $id: ID!
                    $statusFilter: ModelSTATUSFilterInput
                    $buildingCheckInTime: ModelSTATUSStatusByStudentCompositeKeyConditionInput
                    $statusSortDirection: ModelSortDirection
                    ) {

    getSTUDENTS(id: $id) {
      id
      firstName
      lastName
      cardId
      familyId
      adUsername
      imageName
      allowLunch
      accessGroup
      allowEntry
      district
      classId
      inBuilding
      STATUSES(
        buildingCheckInTime: $buildingCheckInTime
        sortDirection: $statusSortDirection
        filter: $statusFilter
      ) {
        items {
          id
          subject
          studentId
          active
          time
          building
          checkInTime
          checkOutTime
          color
          SUBJECT {
            name
            color
            rgbActive
            rgbNotActive
            sectionId
            hidden
            building
            maxTime
            classId
          }
        }
      }
      SUBJECTS {
        items{
          name
          color
          rgbActive
          rgbNotActive
          sectionId
          hidden
          building
          maxTime
          classId
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSTUDENTS = /* GraphQL */ `
  query ListSTUDENTS(
    $filter: ModelSTUDENTSFilterInput
    $sortDirection: ModelSortDirection
    $statusFilter: ModelSTATUSFilterInput
    $buildingCheckInTime: ModelSTATUSStatusByStudentCompositeKeyConditionInput
    $statusSortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    listSTUDENTS(filter: $filter, limit: $limit, nextToken: $nextToken,  sortDirection: $sortDirection) {
      items {
        id
        firstName
        lastName
        cardId
        familyId
        adUsername
        imageName
        allowLunch
        accessGroup
        allowEntry
        district
        inBuilding
        classId
        STATUSES(
          buildingCheckInTime: $buildingCheckInTime
          sortDirection: $statusSortDirection
          filter: $statusFilter
        ) {
          items {
            id
            studentId
            subject
             checkInTime
             checkOutTime
            active
            createdAt
            building
            color
            classId
            SUBJECT {
                  name
                  color
                  rgbActive
                  rgbNotActive
                  sectionId
                  hidden
                  building
                  maxTime
                  classId
            }
          }
        }
        SUBJECTS {
            items {
              name
              color
              rgbActive
              rgbNotActive
              sectionId
              hidden
              building
              maxTime
              classId
              }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const syncSTUDENTS = /* GraphQL */ `
  query SyncSTUDENTS(
    $filter: ModelSTUDENTSFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSTUDENTS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        firstName
        lastName
        cardId
        familyId
        adUsername
        imageName
        allowLunch
        accessGroup
        allowEntry
        district
        inBuilding
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const studentByCardId = /* GraphQL */ `
  query StudentByCardId(
    $cardId: String!
    $sortDirection: ModelSortDirection
    $statusSortDirection: ModelSortDirection
    $filter: ModelSTUDENTSFilterInput
    $statusFilter: ModelSTATUSFilterInput
    $buildingCheckInTime: ModelSTATUSStatusByStudentCompositeKeyConditionInput
    $limit: Int
    $nextToken: String
  ) {
    studentByCardId(
      cardId: $cardId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        cardId
        familyId
        adUsername
        imageName
        allowLunch
        accessGroup
        allowEntry
        district
        inBuilding
        classId
        STATUSES(
          buildingCheckInTime: $buildingCheckInTime
          sortDirection: $statusSortDirection
          filter: $statusFilter
        ) {
          items {
           id
          subject
          studentId
          active
          time
          building
          checkInTime
          checkOutTime
          color
            SUBJECT {
              name
              color
              rgbActive
              rgbNotActive
              sectionId
              hidden
              building
              maxTime
              classId
             }
          }
        }
         SUBJECTS {
         items{
              name
              color
              rgbActive
              rgbNotActive
              sectionId
              hidden
              building
              maxTime
              classId
              }
        }
      }
      nextToken
    }
  }
`;
export const searchSTUDENTS = /* GraphQL */ `
  query SearchSTUDENTS(
    $filter: SearchableSTUDENTSFilterInput
    $sort: [SearchableSTUDENTSSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableSTUDENTSAggregationInput]
  ) {
    searchSTUDENTS(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        firstName
        lastName
        cardId
        familyId
        classId
        adUsername
        imageName
        allowLunch
        accessGroup
        allowEntry
        district
        inBuilding
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;

export const studentsByDistrictUpdateAt = /* GraphQL */ `
  query StudentsByDistrictUpdateAt(
    $district: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $statusFilter: ModelSTATUSFilterInput
    $buildingCheckInTime: ModelSTATUSStatusByStudentCompositeKeyConditionInput
    $statusSortDirection: ModelSortDirection
    $filter: ModelSTUDENTSFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studentsByDistrictUpdateAt(
      district: $district
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        cardId
        familyId
        classId
        adUsername
        imageName
        allowLunch
        accessGroup
        allowEntry
        district
        inBuilding
        currentStatus
        STATUSES(
          buildingCheckInTime: $buildingCheckInTime
          sortDirection: $statusSortDirection
          filter: $statusFilter
        ) {
          items {
            id
            studentId
            subject
            checkInTime
            checkOutTime
            active
            createdAt
            building
            color
            classId
            SUBJECT {
              name
              color
              rgbActive
              rgbNotActive
              sectionId
              hidden
              building
              maxTime
              classId
            }
          }
        }
        SUBJECTS {
          items {
            name
            color
            rgbActive
            rgbNotActive
            sectionId
            hidden
            building
            maxTime
            classId
          }
        }
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
