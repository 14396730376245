import Amplify, { API, graphqlOperation, Storage } from 'aws-amplify'
import React, { useEffect, useState, useCallback } from 'react'

const  AnalysisInfo = ({fileKey}) =>{
    const [link, setLink] = useState(null);
    useEffect(() => {
        const go = async () => {
            const [level, identityId, _key] = fileKey.split('/')
            const link = await Storage.get(_key)
            setLink(link)
    /*
            const data = Object.assign(
                await d3.csv(link, ({ longitude, tot_pop, count }) => ({
                    x: parseFloat(longitude),
                    y: parseFloat(tot_pop),
                    count: parseInt(count)
                })),
                { x: 'Longitude', y: 'Population', title: 'Pop bins by Longitude' }
            )
            drawChart(data)


     */

        }
        go()
    }, [fileKey])
    return (
                <div>

                    link
                </div>
    )
}

export default AnalysisInfo;