import reportWebVitals from './reportWebVitals';
import React from "react";
import ReactDOM from "react-dom/client";
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";
import App from "./App";
import {Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import "./components/scss/style.scss";
import "./index.css"
import * as serviceWorker from './registerServiceWorker'
import Kiosk from "./kiosk";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Authenticator.Provider>
        <Router>
            <Routes>
                <Route exact path="/*" element={<App />} />
                <Route exact path="/home/*" element={<App />} />
                <Route exact path="/kiosk/*" element={<Kiosk />} />
            </Routes>
        </Router>
    </Authenticator.Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()