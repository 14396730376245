import React, {useEffect, useState} from "react";
import {checkUniqueCard} from "../../Services/idcardActions";

function AddNew({ newStudent }) {
  //hold user data
  const formInit = {
    id: "",
    firstName: "",
    lastName: "",
    cardId: "",
    adUsername: "",
    allowLunch: "",
    allowEntry: "",
    imageName: "",
    district: "",
    classId: ""
  };
  const [formData, setFormData] = useState(formInit);
    const handleScan = (e) => {
        checkUniqueCard(e.detail.scanCode).then( r => {
            if(r){
                setFormData({...formData, cardId: e.detail.scanCode});
            } else {
                alert(`Id card: ${e.detail.scanCode} is not unique please choose a new Id card`);
            }
        }).catch(() =>
        {
            alert("Id card lookup error");
        })
    }
  //hangle change event
    useEffect(() =>{
        document.addEventListener('scan', handleScan);
        // cleanup this component
        return () => {
            document.removeEventListener('scan', handleScan);
        }

    },[formData]);
  const handleChange = (event) => {
    const key = event.target.id;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setFormData({ ...formData, [key]: value });
  };
  //handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();

    newStudent(formData);
    //setFormData(formInit);
  };
  return (
    <form className="addnew" onSubmit={handleSubmit}>
      <label className="required">Student Id Number</label>
      <input
        id="id"
        type="text"
        className="inputs"
        value={formData.id}
        onChange={handleChange}
        placeholder="48"
      />
      <label className="required">First Name</label>
      <input
        id="firstName"
        type="text"
        className="inputs"
        value={formData.firstName}
        onChange={handleChange}
        placeholder="John"
      />

      <label className="required">Last Name</label>
      <input
        id="lastName"
        type="text"
        className="inputs"
        value={formData.lastName}
        onChange={handleChange}
        placeholder="Doe"
      />

      <label className="required">Email Address</label>
      <input
        id="adUsername"
        type="text"
        className="inputs"
        value={formData.adUsername}
        onChange={handleChange}
        placeholder="email"
      />
      <label className="required">Access Card Id</label>
      <input
        id="cardId"
        type="text"
        className="inputs"
        value={formData.cardId}
        onChange={handleChange}
        placeholder="1234"
      />
    <label className="required">Class Id </label>
    <input
        id="classId"
        type="text"
        className="inputs"
        value={formData.classId}
        onChange={handleChange}
        placeholder="2024"
    />
      <span className="checkbox">
        Allow Building Entry
        <input
          id="allowEntry"
          type="checkbox"
          checked={formData.allowEntry}
          onChange={handleChange}
        />
      </span>
      <span className="checkbox">
        Allow Off Campus Lunch
        <input
          id="allowLunch"
          type="checkbox"
          checked={formData.allowLunch}
          onChange={handleChange}
        />
      </span>
      <button className="btn-submit">Create new</button>
    </form>
  );
}

export default AddNew;
