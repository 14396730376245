import React from "react";
import moment from 'moment';
import useFitText from "use-fit-text";
function colorToRGBA(color) {
  var cvs, ctx, op;
  cvs = document.createElement('canvas');
  cvs.height = 1;
  cvs.width = 1;
  ctx = cvs.getContext('2d');
  ctx.fillStyle = color;
  ctx.fillRect(0, 0, 1, 1);

  return ctx.getImageData(0, 0, 1, 1).data;
}
const SubjectCard = ({ status }) => {
  const { fontSize, ref } = useFitText();
  moment.locale('en');
  let op;
  const bg = colorToRGBA(status.SUBJECT.color);
  if (status.active) {
    op = .75
  } else {
    op = .25
  }
  let rgb;
  let checkOut;
  if(status.active){
    rgb = status.SUBJECT.rgbNotActive;
    checkOut = <div/>
  } else{
    rgb = "rgba(189, 195, 199)";
    checkOut = <h4  ref={ref}  >End Time: {moment(status.checkOutTime).format("h:mm:ss a")} </h4>;
  }


  return (
    <div className="subject__card" id="${status.id}-card" style={{backgroundColor: rgb}}>
      <div className="top">
        <div></div>
        <h3 id="card-title" ref={ref}  >{status.subject}</h3>
      </div>
      <div className="middle">
        <h4  ref={ref}  >Start Time: {moment(status.checkInTime).format("h:mm:ss a")} </h4>
      </div>
      <div className="bottom">
        {checkOut}
      </div>
    </div>
  );
};

export default SubjectCard;
