import React, { useRef} from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";

import Welcomeinfo from "../components/cards/Welcomeinfo";
import Searchbar from "../components/forms/Searchbar";
import SubjectList from "../components/cards/SubjectList";
import Subjectinfo from "../components/cards/Subjectinfo";
import {fetchSubjects} from "../Services/idcardActions";

const AdminSubjects = ({
                         subjects,
                          setSubjects,
                         currentSubject,
                         setCurrentSubject,
                         deleteSubject,
                         searchTerm,
                         handleSearch,
                         accName
}) => {
  const inputEl = useRef("");
  const navigate = useNavigate()
  const handleFilterFunction = () => {
    handleSearch(inputEl.current.value);
    
  };
  if (subjects.length === 0) {
  fetchSubjects().then(s => {
          setSubjects([...s]);
      });

  }

  const renderSubject = (name, classId) => {
    const user = subjects.filter((element) => {
      return (element.name === name && element.classId === classId);
    });

    setCurrentSubject(JSON.parse(JSON.stringify(user[0])));
    navigate('update')
  };

  const setDelete = () => {
      deleteSubject(currentSubject.name,currentSubject)
  }

  return (
    <section className="admin__view">
      <article className="left">
        <Welcomeinfo accName={accName}/>
        <Searchbar searchTerm={searchTerm} handleSearch={handleSearch} />
        <div className="users-list">
          <div className="user-title">
            <h3>Manage Subjects</h3>
            <select
              type="option"
              onChange={handleFilterFunction}
              ref={inputEl}
            >
              <option value="all">Filter</option>
              <option value={"2024"}>Class 2024</option>
                <option value={"2025"}>Class 2025</option>
            </select>
          </div>
          <SubjectList
            subjects={subjects}
            renderSubject={renderSubject}
          />
        </div>
      </article>
      <article className="right">
        <Subjectinfo currentSubject={currentSubject} />
        <div className="right-manage">
          <NavLink className="btn-new btn" activeclassname="active" to="update">
            Update Info
          </NavLink>
          <NavLink className="btn-new btn" activeclassname="active" to="addnew">
            Add New?
          </NavLink>
            <div className="btn-new btn"  onClick={setDelete}>
                Remove Subject
            </div>
        </div>
        <div className="forms-container">
          <Outlet />
        </div>
      </article>
    </section>
  );
};

export default AdminSubjects;
