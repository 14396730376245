/* eslint-disable */
// this is an auto generated file. This will be overwritten
export const onAnnouncement = /* GraphQL */ `
  subscription OnAnnouncement($QueryExecutionId: ID!) {
    onAnnouncement(QueryExecutionId: $QueryExecutionId) {
      QueryExecutionId
      file {
        bucket
        region
        key
      }
    }
  }
`;
export const onCreateSTATUS = /* GraphQL */ `
  subscription OnCreateSTATUS {
    onCreateSTATUS {
      id
      subject
      studentId
      active
      time
      building
      color
      checkInTime
      checkOutTime
      classId
      SUBJECT {
            name
            sectionId
            hidden
            color
            building
            classId
          }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSTATUS = /* GraphQL */ `
  subscription OnUpdateSTATUS {
    onUpdateSTATUS {
      id
      subject
      studentId
      active
      time
      building
      checkInTime
      checkOutTime
      color
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSTATUS = /* GraphQL */ `
  subscription OnDeleteSTATUS {
    onDeleteSTATUS {
      id
      subject
      studentId
      active
      time
      building
      checkInTime
      checkOutTime
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSUBJECTS = /* GraphQL */ `
  subscription OnCreateSUBJECTS {
    onCreateSUBJECTS {
      name
      color
      sectionId
      hidden
      building
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSUBJECTS = /* GraphQL */ `
  subscription OnUpdateSUBJECTS {
    onUpdateSUBJECTS {
      name
      color
      sectionId
      hidden
      building
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSUBJECTS = /* GraphQL */ `
  subscription OnDeleteSUBJECTS {
    onDeleteSUBJECTS {
      name
      color
      sectionId
      hidden
      building
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDISTRICT = /* GraphQL */ `
  subscription OnCreateDISTRICT {
    onCreateDISTRICT {
      name
      building
      timeZone
      scheduleCheck
      notifications
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDISTRICT = /* GraphQL */ `
  subscription OnUpdateDISTRICT {
    onUpdateDISTRICT {
      name
      building
      timeZone
      scheduleCheck
      notifications
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDISTRICT = /* GraphQL */ `
  subscription OnDeleteDISTRICT {
    onDeleteDISTRICT {
      name
      building
      timeZone
      scheduleCheck
      notifications
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSTUDENTS = /* GraphQL */ `
  subscription OnCreateSTUDENTS {
    onCreateSTUDENTS {
      id
      firstName
      lastName
      cardId
      familyId
      adUsername
      imageName
      allowLunch
      accessGroup
      allowEntry
      district
      inBuilding
      STATUSES {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSTUDENTS = /* GraphQL */ `
  subscription OnUpdateSTUDENTS {
    onUpdateSTUDENTS {
      id
      firstName
      lastName
      cardId
      familyId
      adUsername
      imageName
      allowLunch
      accessGroup
      allowEntry
      district
      inBuilding
      classId
      STATUSES {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSTUDENTS = /* GraphQL */ `
  subscription OnDeleteSTUDENTS {
    onDeleteSTUDENTS {
      id
      firstName
      lastName
      cardId
      familyId
      adUsername
      imageName
      allowLunch
      accessGroup
      allowEntry
      district
      inBuilding
      STATUSES {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const onChangeSTATUS = /* GraphQL */`
  subscription onChangeSTATUS {
    onChangeSTATUS {
      id
      subject
      studentId
      active
      classId
    }
  }
`;
