import React, { useState} from "react";
import Starts from "../components/cards/Starts";
import Employeecard from "../components/cards/Employeecard.jsx";
import SubjectCard from "../components/cards/SubjectCard.jsx";
import {
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody
} from "@aws-amplify/ui-react";
import { DashboardUseSubscription} from "../Services/subscriptions";
import useFitText from "use-fit-text";
import Synergy from "../components/assets/images/Synergy.png"



const  Dashboard = ({building}) => {

  const [students, setStudents] = useState([]);
  const [inBuildingCount, setInBuildingCount] = useState(0);

  DashboardUseSubscription({students, setStudents, inBuildingCount, setInBuildingCount, building,sortDirection:"ASC"});

  return (
      <section className="dashboard__view">

        <article className="left">
          <div className="welcome"/>
          <Starts students={students} inBuildingCount={inBuildingCount}/>
        </article>
        <Table highlightOnHover={true}>
          <TableHead>
            <TableRow>
              <TableCell as="th" className={"right cell_padding"}>Learner</TableCell>
              <TableCell as="th" className={"right cell_padding"}>1</TableCell>
              <TableCell as="th" className={"right cell_padding"}>2</TableCell>
              <TableCell as="th" className={"right cell_padding"}>3</TableCell>
              <TableCell as="th" className={"right cell_padding"}>4</TableCell>
              <TableCell as="th" className={"right cell_padding"}>5</TableCell>
              <TableCell as="th" className={"right cell_padding"}>6</TableCell>
              <TableCell as="th" className={"right cell_padding"}>7</TableCell>
              <TableCell as="th" className={"right cell_padding"}>8</TableCell>
              <TableCell as="th" className={"right cell_padding"}>9</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {students.map((student) => (
              <TableRow key={student.id}>
                <TableCell  className={"right cell_padding"}>

                  <Employeecard className={"emp__card1"}
                                key={student.id}
                                avatar={student.Image}
                                usrname={student.firstName + " " + student.lastName}
                                inBuilding={student.inBuilding}
                    //staffid={student.id}
                    //cardId={student.cardId}
                  />
                </TableCell>
                {student.STATUSES.items.map((activity) => (
                  !activity.SUBJECT.hidden ?
                    <TableCell key={activity.id} className={"right cell_padding"}>
                      <SubjectCard status={activity}/>
                    </TableCell>
                    : null
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </section>
);
};

export default Dashboard;
