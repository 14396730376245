/* eslint-disable */
// this is an auto generated file. This will be overwritten
export const announceQueryResult = /* GraphQL */ `
  mutation AnnounceQueryResult($input: AnnounceInput!) {
    announceQueryResult(input: $input) {
      QueryExecutionId
      file {
        bucket
        region
        key
      }
    }
  }
`;
export const updateStudentActivity = /* GraphQL */ `
  mutation UpdateStudentActivity($input: UpdateStudenActivityInput!) {
    updateStudentActivity(input: $input) {
      id
      firstName
      lastName
      studentId
      allowEntry
      allowLunch
      activeSubject
      inbuilding
      activity {
        id
        subject
        active
        timeSpent
        color
      }
    }
  }
`;
export const createSTATUS = /* GraphQL */ `
  mutation CreateSTATUS(
    $input: CreateSTATUSInput!
    $condition: ModelSTATUSConditionInput
  ) {
    createSTATUS(input: $input, condition: $condition) {
      id
      subject
      studentId
      active
      time
      building
      color
      classId
      checkInTime
      checkOutTime
      createdAt
      updatedAt
    }
  }
`;
export const updateSTATUS = /* GraphQL */ `
  mutation UpdateSTATUS(
    $input: UpdateSTATUSInput!
    $condition: ModelSTATUSConditionInput
  ) {
    updateSTATUS(input: $input, condition: $condition) {
      id
      subject
      studentId
      active
      time
      building
      color
      classId
      checkInTime
      checkOutTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteSTATUS = /* GraphQL */ `
  mutation DeleteSTATUS(
    $input: DeleteSTATUSInput!
    $condition: ModelSTATUSConditionInput
  ) {
    deleteSTATUS(input: $input, condition: $condition) {
      id
      subject
      studentId
      active
      time
      building
      color
      classId
      checkInTime
      checkOutTime
      createdAt
      updatedAt
    }
  }
`;
export const createSUBJECTS = /* GraphQL */ `
  mutation CreateSUBJECTS(
    $input: CreateSUBJECTSInput!
    $condition: ModelSUBJECTSConditionInput
  ) {
    createSUBJECTS(input: $input, condition: $condition) {
      name
      color
      rgbActive
      rgbNotActive
      sectionId
      hidden
      building
      maxTime
      classId
      createdAt
      updatedAt
    }
  }
`;
export const updateSUBJECTS = /* GraphQL */ `
  mutation UpdateSUBJECTS(
    $input: UpdateSUBJECTSInput!
    $condition: ModelSUBJECTSConditionInput
  ) {
    updateSUBJECTS(input: $input, condition: $condition) {
      name
      color
      rgbActive
      rgbNotActive
      sectionId
      hidden
      building
      maxTime
      classId
      createdAt
      updatedAt
    }
  }
`;
export const deleteSUBJECTS = /* GraphQL */ `
  mutation DeleteSUBJECTS(
    $input: DeleteSUBJECTSInput!
    $condition: ModelSUBJECTSConditionInput
  ) {
    deleteSUBJECTS(input: $input, condition: $condition) {
      name
      color
      rgbActive
      rgbNotActive
      sectionId
      hidden
      building
      maxTime
      classId
      createdAt
      updatedAt
    }
  }
`;
export const createDISTRICT = /* GraphQL */ `
  mutation CreateDISTRICT(
    $input: CreateDISTRICTInput!
    $condition: ModelDISTRICTConditionInput
  ) {
    createDISTRICT(input: $input, condition: $condition) {
      name
      building
      timeZone
      scheduleCheck
      notifications
      createdAt
      updatedAt
    }
  }
`;
export const updateDISTRICT = /* GraphQL */ `
  mutation UpdateDISTRICT(
    $input: UpdateDISTRICTInput!
    $condition: ModelDISTRICTConditionInput
  ) {
    updateDISTRICT(input: $input, condition: $condition) {
      name
      building
      timeZone
      scheduleCheck
      notifications
      createdAt
      updatedAt
    }
  }
`;
export const deleteDISTRICT = /* GraphQL */ `
  mutation DeleteDISTRICT(
    $input: DeleteDISTRICTInput!
    $condition: ModelDISTRICTConditionInput
  ) {
    deleteDISTRICT(input: $input, condition: $condition) {
      name
      building
      timeZone
      scheduleCheck
      notifications
      createdAt
      updatedAt
    }
  }
`;
export const createSTUDENTS = /* GraphQL */ `
  mutation CreateSTUDENTS(
    $input: CreateSTUDENTSInput!
    $condition: ModelSTUDENTSConditionInput
  ) {
    createSTUDENTS(input: $input, condition: $condition) {
      id
      firstName
      lastName
      cardId
      familyId
      adUsername
      imageName
      allowLunch
      accessGroup
      allowEntry
      district
      inBuilding
      classId
      STATUSES {
        nextToken

      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSTUDENTS = /* GraphQL */ `
  mutation UpdateSTUDENTS(
    $input: UpdateSTUDENTSInput!
    $condition: ModelSTUDENTSConditionInput
  ) {
    updateSTUDENTS(input: $input, condition: $condition) {
      id
      firstName
      lastName
      cardId
      familyId
      adUsername
      imageName
      allowLunch
      accessGroup
      allowEntry
      district
      inBuilding
      classId
      STATUSES {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSTUDENTS = /* GraphQL */ `
  mutation DeleteSTUDENTS(
    $input: DeleteSTUDENTSInput!
    $condition: ModelSTUDENTSConditionInput
  ) {
    deleteSTUDENTS(input: $input, condition: $condition) {
      id
      firstName
      lastName
      cardId
      familyId
      adUsername
      imageName
      allowLunch
      accessGroup
      allowEntry
      district
      inBuilding
      classId
      STATUSES {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
