import React, {useState, useEffect} from "react";
import SubjectAlert from "../components/Alert/SubjectAlert"
import Userinfo from "../components/cards/Userinfo";
import {addTrack, buildingCheckIn, getCard, buildingCheckOut,getByStudentId} from "../Services/idcardActions";
import onScan from "onscan.js";
import Synergy from "../components/assets/images/Synergy.png"
import {Button,useAuthenticator} from "@aws-amplify/ui-react";
/*onScan.attachTo(document,{
  onKeyDetect: function(iKeyCode, oEvent){
    console.log(iKeyCode)
    console.log(oEvent)
  },
  onKeyProcess: function(sChar, oEvent){
    console.log(sChar)
    console.log(oEvent)
  }
});*/
onScan.attachTo(document, {avgTimeByChar: 60});


const studentInit = {
  id: "",
  firstName: "",
  lastName: "",
  cardId: "",
  familyId: "",
  adUsername: "",
  imageName: "",
  allowLunch: "",
  accessGroup: "",
  allowEntry: true,
  district: "",
  inBuilding: false,
  Image: "null",
  STATUSES: {items:[]},
  SUBJECTS: {items:[]}
};

const Timecard = ({ building

}) => {
  const [alert, setAlert] = React.useState({
    type: 'error',
    text: 'This is a alert message',
    show: false
  })
  const [currentStudent, setCurrentStudent] = useState(studentInit);
  const [message, setMessage]= useState();
  const [status, setStatus] = useState();
  const [studentId, setStudentId] = useState("");


  const delay = ms => new Promise(res => setTimeout(res, ms));

  function onCloseAlert() {
    setAlert({
      type: '',
      text: '',
      show: false
    })
    setStudentId("")
  }
  useEffect(() =>{
    setStudentId("")
    function onShowAlert(type) {
      setAlert({
        type: type,
        text: <>
          {message}
        </>,
        show: true
      })
    }

    if(currentStudent.id !== ""){
      if(currentStudent.allowEntry){
        onShowAlert('success')
      } else {
        onShowAlert('error')
      }
    }

  }, [message])
  useEffect(() => {
    let subj = currentStudent.SUBJECTS.items;
    let filteredResults = [];
    if (subj.length > 0){
      filteredResults = subj && subj.filter(subj=>subj.hidden === false)
    }

    if (!currentStudent.inBuilding) {
      setMessage(<div>Welcome to {building}
        <Userinfo currentStudent={currentStudent} />
      </div>);
    } else {
      setMessage(
        <div>
          <Userinfo currentStudent={currentStudent} />
          <div/>
          <div className="btn-grid">
          {filteredResults.map((activity) => (
            <div className={"btn-subject-cont"} key={activity.name+"-"+activity.classId}>
            <Button className="btn-subject btn" style={{color:activity.rgbActive}} onClick={() => handleAddSubject(activity)}>{activity.name}</Button>
            </div>
          ))}
            <div className={"btn-subject-cont"} key="clockOut">
            <button className="btn-in btn" onClick={clockOutTime}>
              Clock-Out
            </button>
            </div>

          </div>



        </div>);
    }




  }, [currentStudent]);
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      document.getElementById("textboxid").blur();
      handleSubmit(e)

    }
  }

  const handleScan = (e,s) => {
    setStatus();
    console.log(e);
    console.log(e + 'x ' + s);
    getCard(e.detail.scanCode, building).then((r) => {
        setCurrentStudent(r);
      })

  };
  // Register event listener
  let authStatus = useAuthenticator(context => [context.authStatus]);
  React.useEffect(() => {

    document.addEventListener('scan', handleScan);
    //document.addEventListener('scanError', function(oDebug){ console.log(oDebug) })


    // cleanup this component
    return () => {
      document.removeEventListener('scan', handleScan);
      //document.removeEventListener('scanError', function(oDebug){ console.log(oDebug) })
    }
  },[]);


  const handleAddSubject = async (newvalue) =>{

    addTrack(currentStudent, newvalue,building);
    onCloseAlert()
    setStatus(`Updated status ${newvalue.name}`)
    await delay(10000);
    setCurrentStudent(studentInit);
    setStudentId(null)

  }
  const clockInTime = async () => {
    if (!currentStudent.inBuilding && currentStudent.id !== "") {
      buildingCheckIn(currentStudent, "attendanceCheckIn", building);
      onCloseAlert()
      setStatus("You have been checked in!")
      await delay(10000);
      setCurrentStudent(studentInit);
    }
  };
  //function clock-out and return day's timestamp
  const clockOutTime = async () => {
    //chech if user acccount is selected
    buildingCheckOut(currentStudent, "attendanceCheckOut", building);
    onCloseAlert()
    setStatus("You have been checked out!")
    await delay(10000);
    setCurrentStudent(studentInit);
    setStudentId(null)

  };
 const sendSample = (cardId) => {
    onScan.simulate(document, cardId)
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    if(studentId === ""){
      setAlert(
          {
            type: 'error',
          text: 'Please enter a student ID Number"',
          show: true
    })
    } else {
      getByStudentId(studentId, building).then((r) => {
        setCurrentStudent(r);
      })
    }
  }

  return (

    <section className="timecard__view">
      <article className="right">
        <h2>Welcome to {building} please scan your ID or</h2>
        <h2>enter your Student Id</h2>
        <form className="addnew" onSubmit={handleSubmit}>
          <input
              id="textboxid"
              autoFocus
              onFocus={e => e.currentTarget.select()}
              type="tel"
              pattern="[0-9]*"
              inputMode="numeric"
              className="inputs"
              value={studentId}
              onKeyDown={handleKeyDown}
              onChange={(e) => {
                setStudentId(e.target.value)
              }}
              placeholder="Enter Student Id"
          />
          <button className="btn-new btn">Submit</button>
        </form>
        <img src={Synergy} alt="Synergy Logo" />

        <div className="right-manage">
        <SubjectAlert
            header={`Welcome to ${building}`}
            btnText={'Close'}
            text={alert.text}
            type={alert.type}
            show={alert.show}
            onClosePress={onCloseAlert}
            pressCloseOnOutsideClick={true}
            showBorderBottom={true}
            alertStyles={{}}
            headerStyles={{}}
            textStyles={{}}
            buttonStyles={{}}
        />
        </div>
      </article>
    </section>

  );
};

export default Timecard;

/*          <AsyncSelect
            cacheOptions
            defaultOptions
            loadOptions={fetchSubjects}
            onChange={handleAddSubject}
          ></AsyncSelect>
<button className="btn-in btn" onClick={() => sendSample("1738164408")}> Simulate Scan</button>*/

/*
        <div className="right-manage">
          {/*<button className="btn-out btn" onClick={clockInTime}>
            Clock-In
          </button>}


<button className="btn-in btn" onClick={clockOutTime}>
  Clock-Out
</button>
<Button onClick={() => sendSample('121242')}> Simulate Ethan </Button>
<Button onClick={() => sendSample('2698405404')}> Simulate Judge </Button>
<Button onClick={() => sendSample('2698405405')}> Simulate Cole </Button>
</div>
 */
