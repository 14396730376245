import React from "react";
import "../css/style.css"
import Welcomeinfo from "../components/cards/Welcomeinfo";
import Embed from "../components/QuickSite/Embed"
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useNavigate } from "react-router-dom";


const Analytics = ({fetchUsers,setStudents, searchTerm, allStudents, handleSearch,accName, inBuildingCount,currentStudent, setCurrentStudent }) => {
 let navigate = useNavigate();
  const setDash = (student) =>{

    setCurrentStudent(student);

    navigate("../detail")

  }
    if (allStudents.length === 0){
        fetchUsers();
    }

  return (
    <section className="analytics__view">
      <article className="left">
              <Typography variant="h4" component="h1" align="center" color="textPrimary" >
                  Learning Time Dashboard
              </Typography>

                  <Embed />

      </article>
    </section>
      
  );
};

export default Analytics;
