import React, {useEffect} from 'react';
import { API, Auth } from 'aws-amplify';
import CircularProgress from '@mui/material/CircularProgress';
const QuickSightEmbedding = require("amazon-quicksight-embedding-sdk");




function Embed (props){
const [loader, setLoader] = React.useState(true);
const [dashboard, setDashboard] = React.useState(null);

useEffect(() => {
    async function getQuickSightDashboardEmbedURL() {
        const data = await Auth.currentSession();
        const jwtToken = data.getIdToken().getJwtToken();
        const accessToken = data.getAccessToken().getJwtToken();
        const payloadSub = data.idToken.payload.sub;
        const email = data.idToken.payload.email;

        const params = {
            headers: {},
            response: true,
            queryStringParameters: {
                jwtToken: jwtToken,
                accessToken: accessToken,
                payloadSub: payloadSub,
                email: email,
                dashboardId: "49bf2bee-6341-47f6-a258-c9803da0c1c3",
                roleArn: "arn:aws:iam::600727510014:role/amplify-oneschoolview-production-134107-authRole"
            }
        }
        const quicksight = await API.get('oneschool', '/getQuickSiteURL', params);
        console.log(quicksight);
        const containerDiv = document.getElementById("dashboardContainer");

        const options = {
            url: quicksight.data.data.EmbedUrl,
            container: containerDiv,
            height: "AutoFit",
            width: "100%",
            scrolling: "no",
            footerPaddingEnabled: false,
        };
        setDashboard(QuickSightEmbedding.embedDashboard(options));
        setLoader(false);

    }
        getQuickSightDashboardEmbedURL().catch(err => console.log(err));
    },[])

        return (
            <div>
                { loader && (
                    <div style={{ display: 'flex', justifyContent: 'center' ,padding:'5'}}>
                        <br/>
                        <CircularProgress size={300}  /></div>
                )}
                <div id="dashboardContainer">


                </div>
            </div>
        );

}

export default(Embed);