import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Analytics from "./Analytics";
import Admin from "./Admin";
import Dashboard from "./Dashboard";
import Detail from "./DetailDashboard";
import Timecard from "./Timecard";
import AddNew from "../components/forms/AddNew";
import Update from "../components/forms/Update";
import { studentsByDistrictUpdateAt } from "../graphql/queries";
import {createSTUDENTS, createSUBJECTS, deleteSUBJECTS, updateSTUDENTS, updateSUBJECTS} from "../graphql/mutations";
import {fetchSubjects} from "../Services/idcardActions";
import { Storage, API } from 'aws-amplify';
import moment from "moment-timezone";
import AdminSubjects from "./AdminSubjects";
import AddNewSubject from "../components/forms/AddNewSubject";
import UpdateSubject from "../components/forms/UpdateSubject";

const Main = ({user, signOut}) => {
  const accName = user.username;
  const acc = user.username;
  const [inBuildingCount, setInBuildingCount ] = useState(0);
  const [building, setBuilding ] = useState("Synergy");
  const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
  console.log(`USers Groups: ${groups}`);  const [currentuser, setCurrentUser] = useState({
    name: "",
    username: "",
    admin: true
  });
  const studentInit = {
    id: "",
    firstName: "",
    lastName: "",
    cardId: "",
    familyId: "",
    adUsername: "",
    imageName: "",
    allowLunch: "",
    accessGroup: "",
    allowEntry: "",
    district: "",
    inBuilding: "",
    Image: "",
    currentStatus: "",
    classId:"",
    STATUSES: {items:[]},
    SUBJECTS: {items:[]}
  };
  const subjectInit = {
    name: "",
    classId: "",
    sectionId: "",
    color: "",
    rgbActive: "",
    rgbNotActive: "",
    hidden: false,
    building: "",
    maxTime: 42
  }
  const [currentStudent, setCurrentStudent] = useState(studentInit);
  const [subjects, setSubjects] = useState([]);
  const [currentSubject, setCurrentSubject] = useState([subjectInit]);
  const [students, setStudents] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const today  = moment.tz("America/New_York").toISOString(true).slice(0, 10);
  async function fetchUsers() {
    try {
      const studentData = await API.graphql({
        query: studentsByDistrictUpdateAt,
        variables: {
          district: "Mineola",
          buildingCheckInTime: {
            beginsWith: {building: "Synergy", checkInTime: today}
          },
          sortDirection: "DESC",
          statusSortDirection: "ASC"
        }
      });
      let count = 0;
      const studentsData = await Promise.all(studentData.data.studentsByDistrictUpdateAt.items.map(async (student, i) => {
        let newStudent = Object.assign({}, student);
        if (student.inBuilding) {
          count++;
        }
        newStudent.Image = await Storage.get(student.imageName);
        return newStudent;
      }));
      setStudents([...studentsData]);
      setInBuildingCount(count)
    } catch (err) {
      console.log(err);
    }
  }


  useEffect(() => {
    setCurrentUser(acc);

  },[]);

  //update user details request
  const updateUser = async (id, formData) => {
    try {
      let submitInfo =JSON.parse(JSON.stringify(formData));
      delete submitInfo.Image;
      delete submitInfo.STATUSES;
      delete submitInfo.SUBJECTS;
      delete submitInfo.createdAt;
      delete submitInfo.updatedAt;
      const response = await API.graphql({ query: updateSTUDENTS, variables: {input: submitInfo}});
      console.log(response);
      fetchUsers()
      setCurrentStudent(studentInit)

    } catch (err) {
      console.log(err);
      alert("Task Failed :(");
    }
  };

  const updateSubject = async (name, formData) => {
    try {
      let submitInfo =JSON.parse(JSON.stringify(formData));
      delete submitInfo.createdAt;
      delete submitInfo.updatedAt;
      const response = await API.graphql({ query: updateSUBJECTS, variables: {input: submitInfo}});
      console.log(response);
      fetchSubjects().then(s => {
        setSubjects([...s]);
      });
      setCurrentSubject(subjectInit)

    } catch (err) {
      console.log(err);
    }
  };
  const newStudent = async (formData) => {
    try {
      const submitInfo = formData;
      submitInfo.imageName = "images/"+ submitInfo.id + ".png";
      submitInfo.district = "Mineola";
      const response = await API.graphql({ query: createSTUDENTS, variables: {input: submitInfo}});
      console.log(response);


    } catch (err) {
      console.log(err);
      alert("Task Failed :(");
    }
  };
  const addSubject = async (formData) => {
    try {
      const response = await API.graphql({ query: createSUBJECTS, variables: {input: formData}});
      console.log(response);
      fetchSubjects().then(s => {
        setSubjects([...s]);
      });

    } catch (err) {
      console.log(err);
      alert("Task Failed :(");
    }
  };
  //search function
  function handleSearch(str) {
    setSearchTerm(str);

    if (searchTerm !== "") {
      const newList = students.filter((user) =>
        Object.values(user)
          .join(" ")
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
      setSearchResult(newList);

      return searchResult;
    }
    setSearchResult(students);
  }

  //filter function
  function filterUsers(term) {
    setSearchTerm(term.value);


    if (term.value !== "all") {
      if(typeof term.value === "boolean"){
      const newList = students.filter((user) => user[term.field] = term.value);

      //return searchResult;
    }else {
        const newList = students.filter((user) => {
          if (user[term.field] != null) {
            user[term.field].toLowerCase() === term.value.toLowerCase()
          }

        });
        setSearchResult(newList);
      }

    }
    setSearchResult(students);
  }
  return (

    <section className="container__main">
      <Header user={user} signOut={signOut}/>
      <div className="view__main">
        <Routes>
          <Route
            exact
            path="dashboard"
            element={
              <Dashboard building={building}/>
            }
          />
        </Routes>
        <Routes>
          <Route
            exact
            path="admin"
            element={
              <Admin
                fetchUsers ={fetchUsers}
                building={building}
                students={searchTerm.length < 1 ? students : searchResult}
                setStudents={setStudents}
                currentuser={currentuser}
                inBuildingCount={inBuildingCount}
                currentStudent={currentStudent}
                setCurrentStudent={setCurrentStudent}
                allStudents={students}
                searchTerm={searchTerm}
                handleSearch={handleSearch}
                filterUsers={filterUsers}
                accName={accName}
              />
            }
          >
            <Route
              exact
              path="Addnew"
              element={<AddNew newStudent={newStudent} />}
            />
            <Route
              exact
              path="/admin/"
              element={<AddNew newStudent={newStudent} />}
            />
            <Route
              exact
              path="update"
              element={
                <Update
                  setCurrentStudent={setCurrentStudent}
                  currentStudent={currentStudent}
                  updateUser={updateUser}
                  setUsers={setStudents}
                  users={students}
                  studentInit={studentInit}
                />
              }
            />
          </Route>
        </Routes>
        <Routes>
          <Route
              exact
              path="subjectadmin"
              element={
                <AdminSubjects
                    setSubjects = {setSubjects}
                    building={building}
                    subjects={searchTerm.length < 1 ? subjects : searchResult}
                    currentSubject={currentSubject}
                    setCurrentSubject={setCurrentSubject}
                    searchTerm={searchTerm}
                    handleSearch={handleSearch}
                    filterUsers={filterUsers}
                    accName={accName}
                />
              }
          >
            <Route
                exact
                path="update"
                element={
                  <UpdateSubject
                      setCurrentSubject ={setCurrentSubject}
                      currentSubject={currentSubject}
                      updateSubject={updateSubject}
                      subjectInit={subjectInit}
                  />
                }
            />
            <Route
                exact
                path="addnew"
                element={
                  <AddNewSubject
                      building={building}
                      setCurrentSubject ={setCurrentSubject}
                      currentSubject={currentSubject}
                      addSubject={addSubject}
                      subjectInit={subjectInit}
                  />
                }
            />
          </Route>
        </Routes>
        <Routes>
          <Route
              exact
            path="analytics"
            element={
              <Analytics
                setStudents={setStudents}
                fetchUsers={fetchUsers}
                filterUsers={filterUsers}
                allStudents={searchTerm.length < 1 ? students : searchResult}
                searchTerm={searchTerm}
                inBuildingCount={inBuildingCount}
                handleSearch={handleSearch}
                accName={accName}
                currentStudent={currentStudent}
                setCurrentStudent={setCurrentStudent}
              />
            }
            >
          </Route>

        </Routes>
        <Routes>
          <Route
              exact
              path="detail"
              element={<Detail currentStudent={currentStudent} />}
          />
        </Routes>
        <Routes>
          <Route
            exact
            path="timecard"
            element={
              <Timecard building={building}/>
            }
          />
        </Routes>
      </div>
      <Footer />
    </section>

  );
};

export default Main;
