import React, { useState, useEffect, useRef } from "react";
import onScan from "onscan.js";
import {checkUniqueCard} from "../../Services/idcardActions";


const Update = ({
  currentStudent,
  setCurrentStudent,
  updateUser,
  studentInit,
}) => {
  //hold user data
  const [formData, setFormData] = useState({...currentStudent});
  const handleScan = (e) => {
    checkUniqueCard(e.detail.scanCode).then( r => {
        if(r){
            setFormData({...currentStudent, cardId: e.detail.scanCode});
        } else {
            alert(`Id card: ${e.detail.scanCode} is not unique please choose a new Id card`);
        }
    }).catch(() =>
    {
        alert("Id card lookup error");
    })
  }
  // Register event listener
  useEffect(() =>{
    setFormData({...currentStudent});
    document.addEventListener('scan', handleScan);
    // cleanup this component
    return () => {
      document.removeEventListener('scan', handleScan);
    }

    },[currentStudent]);


  const userRef = useRef();

  const handleChange = (event) => {
    const key = event.target.id;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setFormData({ ...formData, [key]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setCurrentStudent(formData);
    //setUsers(users);
    updateUser(currentStudent.id, formData);
    setFormData(studentInit)
  };

  return (
    <form className="addnew" onSubmit={handleSubmit}>
      <label>
        First Name
        <input
          id="firstName"
          type="text"
          ref={userRef}
          className="inputs"
          value={formData.firstName}
          onChange={handleChange}
          placeholder="Jon"
        />
      </label>
      <label>
        Last Name
        <input
          required
          id="lastName"
          type="text"
          className="inputs"
          value={formData.lastName}
          onChange={handleChange}
          placeholder="Doe"
        />
      </label>
      <label>
        Access Card Number
        <input
          id="cardId"
          type="text"
          className="inputs"
          value={formData.cardId}
          onChange={handleChange}
          placeholder="225064872"
          disabled={true}
        />
      </label>
        <label>
            Class Id
        <input
            id="classId"
            type="text"
            className="inputs"
            value={formData.classId}
            onChange={handleChange}
            placeholder="2024"
        />
        </label>
      <label>
        Email Address
        <input
          id="adUsername"
          type="text"
          className="inputs"
          value={formData.adUsername}
          onChange={handleChange}
          placeholder="jdoes24@mineolak12workspace.com"
        />
      </label>
      <span className="checkbox">
        Allow Building Entry
        <input
          id="allowEntry"
          type="checkbox"
          checked={formData.allowEntry}
          onChange={handleChange}
        />
      </span>
      <span className="checkbox">
        Allow Off Campus Lunch
        <input
          id="allowLunch"
          type="checkbox"
          checked={formData.allowLunch}
          onChange={handleChange}
        />
      </span>
      <span className="checkbox">
        In Building
        <input
          id="inBuilding"
          type="checkbox"
          disabled={true}
          checked={formData.inBuilding}
          onChange={handleChange}
        />
      </span>
      <button className="btn-submit">Update</button>
    </form>
  );
};

export default Update;
