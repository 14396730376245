import React, {useEffect} from "react";
import {Auth} from "aws-amplify";
import {useAuthenticator} from "@aws-amplify/ui-react";
import {useNavigate} from "react-router-dom";


const UnAuthorized = ({setIsAuth, setCurrentUser, setGroups, navPath, userName, password}) => {
    const navigate = useNavigate()
    const { authStatus } = useAuthenticator(context => [context.authStatus]);
    useEffect(()  =>{
        Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(r => {
            setIsAuth(true);
            setCurrentUser(r);
            setGroups(r.signInUserSession.accessToken.payload["cognito:groups"])
            navigate(navPath);
        }).catch(err =>{
            console.log("Current Authentication Error: " + err)
            Auth.signIn(userName, password).then(

                newu => {
                    setIsAuth(true);
                    setCurrentUser(newu);
                    setGroups(newu.signInUserSession.accessToken.payload["cognito:groups"]);
                    navigate(navPath);
                    console.log(navPath)
                }).catch(err =>{
                console.log("Current Authentication Error: " + err)
                setIsAuth(false);
            })
        })

    }, [authStatus]);

    return (
        <section id="login">
            <div className="container__login">
                <h2>
                    UnAuthorized
                </h2>
            </div>
        </section>
    );
}

export default UnAuthorized;
