import React, { useState, useEffect, useCallback } from 'react';
import Amplify, { API, graphqlOperation, Storage } from 'aws-amplify'
import * as queries from "../graphql/queries";
import {onAnnouncement} from "../graphql/subscriptions";
import Datepicker from '../components/Datepicker';
import Intro from '../partials/userinfo/Intro';
import AnalysisInfo from "../components/cards/AnalysIsInfo";

function sqlQuery(studentId) {
    return `SELECT subject, SUM(time) as total_time
            FROM "Oneschool"."default"."STATUS-mg7jopvhfzdhjcadnqk3qwx2rq-dev"
            WHERE studentId='${studentId}'
            group by subject;`
}

function Detail({currentStudent}) {
    const [dateFrom, setDateFrom] = useState();
    const [dateTo, setDateTo] = useState();
    const [isSending, setIsSending] = useState(false)
    const [QueryExecutionId, setQueryExecutionId] = useState(null)
    const [fileKey, setFileKey] = useState(null)

    useEffect(() => {
        if (!QueryExecutionId) return

        console.log(`Starting subscription with sub ID ${QueryExecutionId}`)
        const subscription = API.graphql(
            graphqlOperation(onAnnouncement, { QueryExecutionId })
        ).subscribe({
            next: result => {
                console.log('subscription:', result)
                const data = result.value.data.onAnnouncement
                console.log('subscription data:', data)
                setFileKey(data.file)
                setQueryExecutionId(null)
            }
        })

        return () => {
            console.log(`Unsubscribe with sub ID ${QueryExecutionId}`, subscription)
            subscription.unsubscribe()
        }
    }, [QueryExecutionId])
    useEffect(() => {
        async function getData(){
            if (isSending) return
            setIsSending(true)
            setFileKey(null)
            let queryString = sqlQuery(currentStudent.id)
            try {
                const result = await API.graphql(
                    graphqlOperation(queries.startQuery, {
                        input: { QueryString:  queryString}
                    })
                )
                console.log(`Setting sub ID: ${result.data.startQuery.QueryExecutionId}`)
                setIsSending(false)
                setQueryExecutionId(result.data.startQuery.QueryExecutionId)
            } catch (error) {
                setIsSending(false)
                console.log('querry failed ->', error)
            }
        }
        getData();
    }, [currentStudent]);

    useEffect(() =>{

    })

    return (

        <div className='detail__view'>

                        {/* Page header */}
                        <div className='sm:flex sm:items-center mb-5'>

                            {/* Right: Actions */}
                            <div className='grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2'>

                                {/* Datepicker built with flatpickr */}
                                <Datepicker align='left' setDateFrom={setDateFrom} setDateTo={setDateTo}/>

                            </div>

                        </div>

                        {/* Cards */}
                        <div className='grid grid-cols-12 gap-6'>

                            {/* Page Intro */}
                            <Intro currentStudent={currentStudent}/>
                            {dateTo} - {dateFrom}


                        </div>
            {fileKey ? (<AnalysisInfo fileKey={fileKey} /> ):
                (
                    <div>
                        {(isSending || QueryExecutionId) && (
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        )}
                    </div>
                )}

        </div>
    );
}

export default Detail;

