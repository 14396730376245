import {API,Storage} from "aws-amplify";
import {studentByCardId, listSTUDENTS, listSUBJECTS,getSTUDENTS} from "../graphql/queries";
import {updateStudentActivity,updateSTUDENTS} from "../graphql/mutations";
import  moment  from "moment-timezone";
import * as queries from "../graphql/queries";

export function fetchIdCards () {
    try {
        const apiData = API.graphql({query: listSTUDENTS, authMode: "AMAZON_COGNITO_USER_POOLS"});
        console.log('apiDate', apiData)
        const apiDataList = apiData.data.listSTUDENTS.items;
        console.log('id Card list', apiDataList);
       return  apiDataList;
    } catch (error) {
        console.log('error on fetching songs', error);
    }
};
export async function fetchSubjects(){
    try{

        let rr;
        await API.graphql({query: listSUBJECTS, authMode: "AMAZON_COGNITO_USER_POOLS"}).then(r => {
            if(r.data.listSUBJECTS.items.length === 0){
                rr = [ {
                    name: "",
                    classId: "",
                    sectionId: "",
                    color: "",
                    rgbActive: "",
                    rgbNotActive: "",
                    hidden: false,
                    building: "",
                    maxTime: 42
                }];
            } else {
                rr = r.data.listSUBJECTS.items;
            }

        });
        return rr;
    } catch(error) {
        console.log("Error with getItem ", error);
    }


}

export async function getCard(cardId, building) {
    console.log("Id Sent to getItem: ", cardId)
    const studentError = {
        id: "0",
        firstName: "No Student",
        lastName: "Found",
        cardId: cardId,
        familyId: "",
        adUsername: "",
        imageName: "",
        allowLunch: false,
        accessGroup: "",
        allowEntry: false,
        district: "",
        inBuilding: false,
        Image: "",
        classId:"0",
        STATUSES: {items:[]},
        SUBJECTS: {items:[]}
    };
   let image;
    const today  = moment.tz("America/New_York").toISOString().slice(0, 10)
    console.log(today)
    try {
        let item;
        //statusFilter: {subject: {eq: "attendanceCheckIn"}}
        await API.graphql({
                query: studentByCardId,
                variables: {
                    cardId: cardId,
                  buildingCheckInTime: {
                        beginsWith: {building: building, checkInTime: today}
                    },
                    statusSortDirection: "DESC"
                }
        }).then(response => {
            console.log(response.data.studentByCardId.items[0]);
             if (response.data.studentByCardId.items.length === 0){
                 item = studentError
             } else if (response.data.studentByCardId.items.length === 1) {
                 item = response.data.studentByCardId.items[0];
                 if(response.data.studentByCardId.items[0].STATUSES.items.length === 0){
                     buildingCheckIn(response.data.studentByCardId.items[0], "attendanceCheckIn", "Synergy");
                 } else if(!response.data.studentByCardId.items[0].inBuilding){
                   buildingCheckIn(response.data.studentByCardId.items[0], "attendanceCheckIn", "Synergy");
                   response.data.studentByCardId.items[0].inBuilding = true;
                 }
             } else {
                 item = {firstName: "More then one users", lastName: "With the Same Id Card", allowEntry: false, studentId: "None", cardId: cardId, allowLunch: false}
             }


        });
        item.Image = await Storage.get(item.imageName);
        return  item;
    } catch (error){
        console.log("Error with getItem ", error);
    }
}
export async function getByStudentId(studentId, building) {
    console.log("Id Sent to getItem: ", studentId)
    const studentError = {
        id: "0",
        firstName: "No Student",
        lastName: "Found",
        studentId: studentId,
        familyId: "",
        adUsername: "",
        imageName: "",
        allowLunch: false,
        accessGroup: "",
        allowEntry: false,
        district: "",
        inBuilding: false,
        Image: "",
        classId:"0",
        STATUSES: {items:[]},
        SUBJECTS: {items:[]}
    };
    let image;
    const today  = moment.tz("America/New_York").toISOString().slice(0, 10)
    console.log(today)
    try {
        let item;
        //statusFilter: {subject: {eq: "attendanceCheckIn"}}
        await API.graphql({
            query: getSTUDENTS,
            variables: {
                id: studentId,
                buildingCheckInTime: {
                    beginsWith: {building: building, checkInTime: today}
                },
                statusSortDirection: "DESC"
            }
        }).then(response => {
            console.log(response.data.getSTUDENTS);
            if (!response.data.getSTUDENTS){
                item = studentError
            } else{
                item = response.data.getSTUDENTS;
                if(response.data.getSTUDENTS.STATUSES.items.length === 0){
                    buildingCheckIn(response.data.getSTUDENTS, "attendanceCheckIn", "Synergy");
                } else if(!response.data.getSTUDENTS.inBuilding){
                    buildingCheckIn(response.data.getSTUDENTS, "attendanceCheckIn", "Synergy");
                    response.data.getSTUDENTS.inBuilding = true;
                }
            }


        });
        item.Image = await Storage.get(item.imageName);
        return  item;
    } catch (error){
        console.log("Error with getItem ", error);
    }
}
export async function checkUniqueCard(cardId){
    let r;
    try {
        await API.graphql({
            query: studentByCardId,
            variables: {
                cardId: cardId,
                statusSortDirection: "DESC"
            }
        }).then(response => {

            r = response.data.studentByCardId.items.length ===0;
        }).catch(() => {r = false});
        return  r;
    } catch (error){
        console.log("Error with getItem ", error);
        return false;
    }
}
export async function addTrack(student,subject,building) {
    let oldid = "Noid"
    let checkInTime = "00:00:00"
    if(student.STATUSES.items.length > 0 && student.STATUSES.items[0].subject !== "attendanceCheckIn"){
        oldid = student.STATUSES.items[0].id
        checkInTime = student.STATUSES.items[0].checkInTime
    }

    const track = {
        id: oldid,
        subject: subject.name,
        classId: student.classId,
        checkInTime: checkInTime,
        studentId: student.id,
        building: building
    };
    //await API.graphql({query: queries.getStudentInfo, variables: {cardId: cardId}})
    await API.graphql({ query: updateStudentActivity, variables: {input: track}}).then(response =>
        {
            console.log(response);
        }
    );

}
export async function buildingCheckIn(student,subject,building) {
    try {
        if ("id" in student) {
            const track = {
                subject: subject,
                studentId: student.id,
                building: building,
                classId: student.classId,
                color: "white"
            };
            //await API.graphql({query: queries.getStudentInfo, variables: {cardId: cardId}})
            await API.graphql({query: updateStudentActivity, variables: {input: track}}).then(response => {
                    console.log(response);
                }
            )

        }
    } catch (err){
        console.log(err)
    }

}

export async function buildingCheckOut(student,subject,building) {
  try {
    if ("id" in student) {
        const track = {
            subject: "attendanceCheckOut",
            studentId: student.id,
            building: building,
            classId: student.classId,
            color: "white"
        };
      //await API.graphql({query: queries.getStudentInfo, variables: {cardId: cardId}})
      await API.graphql({query: updateStudentActivity, variables: {input: track}}).then(response => {
          console.log(response);
        }
      )
    }
  } catch (err){
    console.log(err)
  }

}
