import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import Timecard from "./features/Timecard";
import Dashboard from "./features/Dashboard";
import React, {useEffect, useState} from "react";
import { Auth } from 'aws-amplify';
import { useAuthenticator } from '@aws-amplify/ui-react';
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import UnAuthorized from "./features/UnAuthorized"
const Kiosk = () =>{
    const search = useLocation().search;
    const userName = new URLSearchParams(search).get("username");
    const password = new URLSearchParams(search).get("password");
    const [isAuth,setIsAuth] = useState(false);
    const [building, setBuilding ] = useState("Synergy");
    const [ currentUser, setCurrentUser ] = useState();
    const { authStatus } = useAuthenticator(context => [context.authStatus]);
    const [groups, setGroups] =useState([]);

    console.log(userName, password)
    console.log("Auth Status: "+ authStatus)
    console.log(search);
    let encodedS = base64_encode(search);
    console.log(encodedS);
    useEffect(()  =>{
        Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(r => {
            setIsAuth(true);
            setCurrentUser(r);
            setGroups(r.signInUserSession.accessToken.payload["cognito:groups"])
        }).catch(err =>{
            console.log("Current Authentication Error: " + err)
            Auth.signIn(userName, password).then(

                newu => {

                setIsAuth(true);
                setCurrentUser(newu);
                setGroups(newu.signInUserSession.accessToken.payload["cognito:groups"])

            }).catch(err =>{
                console.log("Current Authentication Error: " + err)
                setIsAuth(false);
            })
        })

    },[authStatus]);

    console.log("New Auth Status: "+ authStatus)

    return (
        <>
            {!isAuth ? "Not Authorized" :
            <Routes>
                {groups.includes("Kiosk") ? <Route exact path="timecard" element={<Timecard building={building}/>} /> :
                    <Route exact path="noauth" element={<UnAuthorized setIsAuth={setIsAuth} setCurrentUser={setCurrentUser} setGroups={setGroups} navPath={"timecard"} userName={userName}/>} />}
                {groups.includes("Wallboard") ? <Route exact path="dashboard" element={<Dashboard building={building}/>} /> :
                    <Route exact path="noauth" element={<UnAuthorized setIsAuth={setIsAuth} setCurrentUser={setCurrentUser} setGroups={setGroups} navPath={"dashboard"} password={password}/>} />}
            </Routes>}
        </>
    );

}
export default Kiosk;